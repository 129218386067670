<template>
  <div>
    <v-card class="py-1">
      <v-layout align-center class="mt-1">
        <v-btn outlined color="primary" @click="$router.go(-1)" tile class="mx-2 my-auto">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <v-tabs v-model="tab" centered>
          <v-tab href="#configuration">Configurations</v-tab>
          <v-tab href="#builder">Form Builder</v-tab>
          <v-tab href="#responses" v-if="modelObj._id && modelObj.configuration">Responses</v-tab>
        </v-tabs>
        <v-text-field placeholder="Name" outlined hide-details dense v-model="modelObj.name" class="my-auto"
        style="width: 400px;"></v-text-field>
        <v-btn color="success" @click="saveUpdateHandler()" tile class="mx-2 my-auto"> Save </v-btn>
      </v-layout>
    </v-card>
    <template v-if="tab === 'configuration'">
      <form-config :modelObj.sync="modelObj"></form-config>
    </template>
    <template v-else-if="tab === 'builder'">
      <form-builder :modelObj.sync="modelObj" class="mx-2 my-n1"></form-builder>
    </template>
    <template v-else-if="tab === 'responses'">
      <form-responses class="ma-2"></form-responses>
    </template>
  </div>
</template>
<script>
export default {
  data () {
    return {
      previewObj: {},
      modelObj: {
        configuration: {},
        fields: []
      },
      tab: 0,
      formId: this.$route.params.id
    }
  },
  components: {
    'form-config': () => import('./Builder/FormConfig.vue'),
    'form-builder': () => import('./Builder/FormBuilder.vue'),
    'form-responses': () => import('./Builder/FormResponses.vue'),
  },
  mounted () {
    if (this.$route.params.id) {
      this.getSingleRecordHandler(this.$route.params.id)
    }
  },
  methods: {
    getSingleRecordHandler (id) {
      this.$_execute('get', `forms/${id}`).then(({ data }) => {
        if (!data.configuration) data.configuration = {}
        this.modelObj = data
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `forms/${this.modelObj._id}` : `/forms`
      let method = this.modelObj._id ? `put` : `post`
      // Logic to construct related modules
      const relatedModules = []
      if (!this.modelObj.configuration.relatedModulesClone) this.modelObj.configuration.relatedModulesClone = []
      this.modelObj.configuration.relatedModulesClone.forEach(x => {
        var moduleExistObj = this.modelObj.configuration.relatedModules.find(y => y.formId === x)
        relatedModules.push({ formId: x, isinternal: moduleExistObj ? moduleExistObj.isinternal : false })
      })
      // Save to DB
      this.modelObj.configuration.relatedModules = relatedModules
      this.modelObj.dummyData = 'Riyaz'
      this.$_execute(method, url, this.modelObj).then((response) => {
        if (method == 'post') this.$router.push(`/forms/actions/${response.data._id}`)
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
      })
    }
  }
}
</script>

